import styled from "styled-components";
import Carousel from "react-elastic-carousel";

const Slider = styled.section`
  position: relative;
  padding-bottom: 100px;

  h3 {
    font-family: ${({ theme }) => theme.fonts.ttNormsBold};
    font-size: 24px;
    font-weight: normal;
    line-height: 36px;
    padding-bottom: 12px;
    border-bottom: 1.5px solid ${({ theme }) => theme.colors.darkGrey};
    margin: 0;
  }

  .navigation {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;

    button {
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      padding-left: 30px;
      cursor: pointer;
    }
  }
`;

const CarouselWrapper = styled(Carousel)`
  .rec-slider-container {
    margin: 0;
  }
`;

export default {
  CarouselWrapper,
  Slider,
};
