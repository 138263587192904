import React, { createRef } from "react";
import Container from "../../common/Container";
import ChevronLeft from "../../../assets/images/chevron_left.svg";
import ChevronRight from "../../../assets/images/chevron_right.svg";
import S from "./styles";

interface SliderProps {
  title?: string;
  children: JSX.Element[];
  carouselSettings?: any;
  titleStyle?: any;
  [rest: string]: any;
}

const Slider = ({
  title,
  children,
  carouselSettings,
  titleStyle,
  ...rest
}: SliderProps) => {
  const carouselRef: any = createRef();

  return (
    <S.Slider {...rest}>
      <Container>
        {title && <h3 style={titleStyle}>{title}</h3>}
        <div className="navigation">
          <button
            className="prev"
            onClick={() => carouselRef.current.slidePrev()}
          >
            <ChevronRight style={{ transform: 'rotate(180deg)' }} />
          </button>
          <button
            className="next"
            onClick={() => carouselRef.current.slideNext()}
          >
            <ChevronRight />
          </button>
        </div>
        <S.CarouselWrapper
          itemsToShow={3.5}
          showArrows={false}
          renderPagination={() => <span></span>}
          style={{ margin: 0 }}
          ref={carouselRef}
          {...carouselSettings}
        >
          {children}
        </S.CarouselWrapper>
      </Container>
    </S.Slider>
  );
};

export default Slider;
